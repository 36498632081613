import React from 'react'
import DocPdfViewer from '../../components/docPdfViewer/DocPdfViewer'

const DocWeb = () => {
  return (
    <DocPdfViewer
      link={require('../../../static/doc/immence_web_portfolio.pdf').default}
    />
  )
}

export default DocWeb
